import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {



  constructor(
    public translateService: TranslateService,
    private location: Location,
  ) {}
}
