import { Component, OnInit, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { Router ,RouterLink} from '@angular/router';
import {trigger, style, query, transition, stagger, animate } from '@angular/animations'
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { LanguageService } from 'src/app/services/language/language.service';
import { ThisReceiver } from '@angular/compiler';
import { TemplateManagerService } from 'src/app/services/fire/template.service';

import { ActivatedRoute } from '@angular/router';

import jsPDF from 'jspdf';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations:[
    trigger("animateMenu",[
      transition(":enter",[
        query("*", [
          style({opacity: 0, transform: "translateY(-50%)"}),
          stagger(50,[
            animate(
              "250ms cubic-bezier(0.35, 0, 0.25, 1)",
              style({opacity: 1, transform: "none"}))
          ])
        ])
      ])
    ])
  ]
})



export class HeaderComponent implements OnInit {
  Template: any;
  primaryTemplatevalue: any;
downloadPriceList() {
throw new Error('Method not implemented.');
}

  responsiveMenuVisible: Boolean = false;
  pageYPosition: number;
  languageFormControl: FormControl= new FormControl();
  cvName: string = "";
  showPasswordInput = false;
  password = '';
  correctPassword:string;
  adminSuccess: boolean = false;
  dataCollection: any[];



  constructor(
    private router: Router,
    public analyticsService: AnalyticsService,
    public languageService: LanguageService,
    private templateManagerService: TemplateManagerService,
    private route : Router
  ) { 

  }

  ngOnInit(): void {
    
    
    this.templateManagerService.getDataCollection().subscribe(data => {
      this.correctPassword = data[0].adminPwd;
      localStorage.setItem('adminPwd',this.correctPassword)
    });
  }


  getPrimaryTemplate() {
    this.templateManagerService.getPrimaryTemplateFromFB().subscribe(
      template => {
        this.Template = template;
        
        this.generatePdf();
        debugger
        // this.primaryTemplatevalue = template.categories.map(category => ({
        //   ...category,
        //   fields: category.fields.map(field => ({
        //     ...field,
        //     count: 0,
        //     category: category.name
        //   }))
        // }));
        console.log('Primary Template:', this.primaryTemplatevalue);
      },
      error => {
        console.error('Error fetching primary template:', error);
      }
    );
  }

  downloadPDF() {
    this.getPrimaryTemplate();
    
    
  }


  generatePdf() {
    const doc = new jsPDF();
    const margin = 10;
    const lineHeight = 10;
    const columnWidths = [20, 110, 30, 30]; // Adjust widths based on the reordered columns
    const pageWidth = doc.internal.pageSize.width; // Page width in PDF units
    const pageHeight = doc.internal.pageSize.height; // Page height in PDF units
    const headerHeight = lineHeight;
    const rowHeight = lineHeight;
    const gapAfterDetails = 60; // Gap between shop details and table
    const maxRowsPerPage = Math.floor((pageHeight - margin * 2 - headerHeight - gapAfterDetails) / rowHeight); // Adjust for additional text space

    // Define the desired order of headers and their corresponding JSON keys
    const headerLabels = ['S.NO', 'PRODUCT', 'PRICE', 'DISCOUNT'];
    const headerKeys = ['id', 'product', 'price', 'discount'];
    
    // Create a mapping between JSON keys and header labels
    const keyToLabelMap: { [key: string]: string } = {};
    headerKeys.forEach((key, index) => {
      keyToLabelMap[key] = headerLabels[index];
    });

    // Extract and filter the data from the nested structure
    const data = this.Template.categories.flatMap(category => category.fields)
      .map(item => {
        const { image, count, availability, ...filteredItem } = item;
        return filteredItem; // Return filtered item without image, count, and availability
      });

    if (data.length === 0) return; // Exit if no data to prevent errors

    const totalWidth = columnWidths.reduce((a, b) => a + b, 0);

    // Additional text details
    const shopName = "Viswai Crackers";
    const date = new Date().toLocaleDateString(); // Current date
    const email = "viswaicrackers@gmail.com";
    const phone = "+91 9629 586 991";

    // Function to draw table borders
    const drawBorders = () => {
      // Top border
      doc.line(margin, margin + gapAfterDetails, margin + totalWidth, margin + gapAfterDetails);
      // Left border
      doc.line(margin, margin + gapAfterDetails + headerHeight, margin, margin + gapAfterDetails + headerHeight + (maxRowsPerPage * rowHeight));
      // Right border
      doc.line(margin + totalWidth, margin + gapAfterDetails + headerHeight, margin + totalWidth, margin + gapAfterDetails + headerHeight + (maxRowsPerPage * rowHeight));
    };

    // Function to draw row borders
    const drawRowBorders = (startY: number, endY: number) => {
      doc.line(margin, startY, margin + totalWidth, startY); // Top border of the row
      doc.line(margin, endY, margin + totalWidth, endY); // Bottom border of the row
    };

    // Function to draw column borders
    const drawColumnBorders = () => {
      let x = margin;
      columnWidths.forEach((width) => {
        doc.line(x, margin + gapAfterDetails + headerHeight, x, margin + gapAfterDetails + headerHeight + (maxRowsPerPage * rowHeight)); // Vertical border
        x += width;
      });
    };

    let y = margin; // Start position for shop details

    // Calculate centered x-coordinate for shop details
    const getCenteredX = (text: string) => {
      const textWidth = doc.getTextWidth(text);
      return (pageWidth - textWidth) / 2;
    };

    // Add shop name and additional details only on the first page
    doc.setFont("helvetica", "bold");
    doc.text(shopName, getCenteredX(shopName), y);
    doc.setFont("helvetica", "normal");
    doc.text(`Date: ${date}`, getCenteredX(`Date: ${date}`), y + 10);
    doc.text(`Email: ${email}`, getCenteredX(`Email: ${email}`), y + 20);
    doc.text(`Phone: ${phone}`, getCenteredX(`Phone: ${phone}`), y + 30);

    y += gapAfterDetails; // Move y position down for the table

    // Add headers to the PDF
    headerKeys.forEach((key, index) => {
      doc.setFont("helvetica", "bold"); // Set font to bold for headers
      doc.text(keyToLabelMap[key], margin + columnWidths.slice(0, index).reduce((a, b) => a + b, 0), y);
      doc.setFont("helvetica", "normal"); // Reset font to normal for other text
    });
    
    y += headerHeight;

    // Add data rows to the PDF with pagination
    data.forEach((item, index) => {
      if (y + rowHeight > pageHeight - margin) { // Check if we need to create a new page
        drawBorders(); // Draw borders before adding data on new page
        drawColumnBorders(); // Draw column borders before adding data on new page
        doc.addPage();
        y = margin; // Reset y position for the new page

        // Add shop name and additional details on the new page
        doc.setFont("helvetica", "bold");
        doc.text(shopName, getCenteredX(shopName), y);
        doc.setFont("helvetica", "normal");
        doc.text(`Date: ${date}`, getCenteredX(`Date: ${date}`), y + 10);
        doc.text(`Email: ${email}`, getCenteredX(`Email: ${email}`), y + 20);
        doc.text(`Phone: ${phone}`, getCenteredX(`Phone: ${phone}`), y + 30);
        y += gapAfterDetails; // Move y position down for the table after details

        // Add headers again on the new page
        headerKeys.forEach((key, index) => {
          doc.setFont("helvetica", "bold"); // Set font to bold for headers
          doc.text(keyToLabelMap[key], margin + columnWidths.slice(0, index).reduce((a, b) => a + b, 0), y);
          doc.setFont("helvetica", "normal"); // Reset font to normal for other text
        });

        y += headerHeight;
      }

      // Add data row
      headerKeys.forEach((key, index) => {
        doc.text(item[key].toString(), margin + columnWidths.slice(0, index).reduce((a, b) => a + b, 0), y);
      });

      // Draw row borders
      drawRowBorders(y, y + rowHeight);

      y += rowHeight;
      
      // Draw table borders at the end of the last page
      if (index === data.length - 1) {
        drawBorders();
        drawColumnBorders(); // Draw column borders on the last page
        // Draw bottom border for the last row
        doc.line(margin, y, margin + totalWidth, y);
      }
    });

    // Save the PDF
    doc.save('pricelist.pdf');
}

  
  togglePasswordInput() {
    
    this.showPasswordInput = this.route.url=='/admin' || this.adminSuccess ? false : true;
    if(this.route.url=='/billing'){
      this.showPasswordInput =false
    }
    
    
   
    if(this.adminSuccess){
      this.router.navigateByUrl('/dummy', { skipLocationChange: true }).then(() => {
        // Then navigate back to the admin route
        this.router.navigate(['/admin']);
      });
  }
}


  checkPassword() {
    
    if (this.password === this.correctPassword) {
      this.router.navigate(['/admin']).then(() => {
        localStorage.setItem("admin","true");
        this.adminSuccess = true;
        this.showPasswordInput = false;
        console.log('Navigation successful');
      }).catch(err => {
        console.error('Navigation failed', err);
      });
      
    } else {
      alert('Incorrect password');
    }
  }
  
  redirectTo(goto:any){
    this.router.navigate([goto]).then(()=>{
      this.responsiveMenuVisible = !this.responsiveMenuVisible;
      console.log('Navigation successful');
      this.showPasswordInput=false;
    }).catch(err => {
      console.error('Navigation failed', err);
    });
  }

  scroll(el) {
    if(document.getElementById(el)) {
      document.getElementById(el).scrollIntoView({behavior: 'smooth'});
      this.showPasswordInput=false;
    } else{
      this.router.navigate(['/home']).then(()=> document.getElementById(el).scrollIntoView({behavior: 'smooth'}) );
    }

  }

  downloadCV(){
    this.cvName = "PriceList.pdf"
    let url = window.location.href;

    // Open a new window with the CV
    window.open(url + "/../assets/cv/" + this.cvName, "_blank");

  }

  @HostListener('window:scroll', ['getScrollPosition($event)'])
    getScrollPosition(event) {
        this.pageYPosition=window.pageYOffset
    }

    changeLanguage(language: string) {
      this.languageFormControl.setValue(language);
    }

    toggleMenu() {
      this.responsiveMenuVisible = !this.responsiveMenuVisible;
    }
}

