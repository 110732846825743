<div class="container-fluid" id="admin">
  <!-- Fullscreen Image Overlay -->
  <div class="image-overlay" *ngIf="showFullscreen" style="z-index: 9000;">
    <div class="overlay-content" style="text-align: center;">
      <span class="close-button" (click)="closeImage()">&times;</span>
      <img
        [src]="fullscreenImage"
        width="40%"
        height="unset"
        alt="Fullscreen Imagea"
        class="fullscreen-image"
      />
    </div>
  </div>
  

  <div id="showTemplate" *ngIf="!showCreateTempate">
    <div class="row">
      <div *ngFor="let key of templates$ | async; let i = index">
        <div class="card">
          <h1 style="font-size: 1.5rem;
    text-align: center;
    color: #000;">{{ key.templatename }}</h1>
          <button
            (click)="editTemplate(key)"
            [ngStyle]="{
              'background-color': key.isPrimary ? '#28a745' : 'black'
            }"
          >
            EDIT
         
  
          <ng-container *ngIf="i != 0">
            
            <button 
        
            style="right: 54px;
    top: 0px;"
            (click)="deleteTemplate(key)"
            [ngStyle]="{
              'background-color': key.isPrimary ? '#28a745' : 'black'
            }"
          >
            DELETE
          </button>
          </ng-container>
        </button>
      
        <button
        style="right: 150px;"
        (click)="duplicateData(key)"
        [ngStyle]="{
          'background-color': key.isPrimary ? '#28a745' : 'black'
        }"
      >
        DUPLICATE
      </button>
         
          <input
            type="radio"
            name="itemSelection"
            (change)="selectItem(key.id)"
            [checked]="key.isPrimary"
          />
        </div>
      </div>
    </div>
    <div class="row makecenter">
      <div *ngIf="dataValue.length == 0">
        <p>No template</p>
      </div>
      <div>
        <button
          class="btn btn-outline-primary"
          style="margin-right: 2px"
          (click)="setPrimary()"
        >
          Set primary
        </button>
        <button class="btn btn-outline-primary" (click)="CreateNew()">
          Create new
        </button>
      </div>
    </div>
  </div>

  <div style="margin-top: 10px" *ngIf="!showCreateTempate && (BillingData.length > 0 ||  StoreData.length > 0)">
    <app-tabs>
      <app-tab tabTitle="BILLING" [active]="true" class="billing">
        <div class="table-responsive">
          <table class="table table-bordered table-info table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Address</th>
                <th scope="col">Email</th>
                <th scope="col">Total Price</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let bill of BillingData; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ bill.customerName }}</td>
                <td>{{ bill.customerPhoneNumber }}</td>
                <td>{{ bill.customerAddress }}</td>
                <td>{{ bill.customerEmail }}</td>
                <td>{{ bill.filterTotalPrice }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-tab>
      <app-tab tabTitle="ONLINE">
        <div class="table-responsive">
          <table class="table table-bordered table-info table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Address</th>
                <th scope="col">Email</th>
                <th scope="col">Total Price</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let store of StoreData; let i = index">
                <th scope="row">{{ i + 1 }}</th>
                <td>{{ store.customerName }}</td>
                <td>{{ store.customerPhoneNumber }}</td>
                <td>{{ store.customerAddress }}</td>
                <td>{{ store.customerEmail }}</td>
                <td>{{ store.filterTotalPrice }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </app-tab>
    </app-tabs>
  </div>

  <div class="row justify-content-center" *ngIf="showCreateTempate">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div style="display: flex; justify-content: space-between">
        <div class="temp-input">
          <label for="templatename">Template Name*</label>
          <input
            id="templatename"
            formControlName="templatename"
            placeholder="Enter template name"
          />
        </div>
        <div class="discount-input">
          <label for="discount">Discount*</label>
          <input
            id="discount"
            formControlName="discount"
            placeholder="Enter discount value"
          />
        </div>
      </div>

      <div formArrayName="categories">
        <div
          *ngFor="let category of categories.controls; let catIndex = index"
          [formGroupName]="catIndex"
        >
          <div class="category-input">
            <div class="card-header" (click)="toggleEditCollapse(catIndex)" [attr.aria-expanded]="!isEditCollapsed[catIndex]"
            aria-controls="collapseExample" style="display: flex;
    justify-content: space-between;">
            <div>
              <label for="categoryname-{{ catIndex }}"
              >Category</label
            >
            <input
              id="categoryname-{{ catIndex }}"
              formControlName="name"
              placeholder="Enter category name"
            />
            <button type="button" *ngIf="catIndex === categories.controls.length - 1" (click)="addCategory()">Add Category</button>
            <button type="button" (click)="removeCategory(catIndex)">
              Remove Category
            </button>
            <button
              type="button"
              *ngIf="catIndex > 0"
              (click)="moveCategoryUp(catIndex)"
            >
              ↑
            </button>
            <button
              type="button"
              *ngIf="catIndex < categories.length - 1"
              (click)="moveCategoryDown(catIndex)"
            >
              ↓
            </button>
            </div>
            <div>
             <img src="assets/arrow.png" alt="image"  width="25px" height="25px"
            >
            </div>
            
          </div>

          <div #collapse="ngbCollapse"
           [(ngbCollapse)]="isEditCollapsed[catIndex]"
           
        class="collapse-body">
            <div style="display: flex; margin-left: -2px">
    
              <div class="heading">Product*</div>
              <div class="heading">Price*</div>
              <div class="heading">Discount</div>
              <div class="heading">Availability</div>
              <div class="heading">Image</div>
            </div>
  
            <div formArrayName="fields">
              <div
                *ngFor="
                  let field of getCategoryFields(catIndex).controls;
                  let fieldIndex = index
                "
                [formGroupName]="fieldIndex"
              >
                <div>
           
                  <input
                    id="product-{{ catIndex }}-{{ fieldIndex }}"
                    formControlName="product"
                    placeholder="Enter product"
                  />
                  <input
                    id="price-{{ catIndex }}-{{ fieldIndex }}"
                    type="number"
                    formControlName="price"
                    placeholder="Enter price"
                  />
                  <input
                    id="discount-{{ catIndex }}-{{ fieldIndex }}"
                    type="number"
                    formControlName="discount"
                    placeholder="Enter discount"
                    readonly
                  />
                  <input
                    id="availability-{{ catIndex }}-{{ fieldIndex }}"
                    type="checkbox"
                    formControlName="availability"
                  />
                  <input
                    id="image-{{ catIndex }}-{{ fieldIndex }}"
                    type="file"
                    (change)="onImageSelected($event, catIndex, fieldIndex)"
                  />
                  <button
                    type="button"
                    (click)="removeField(catIndex, fieldIndex)"
                  >
                    Remove Item
                  </button>
                </div>
              </div>
              <div class="row" style="justify-content: center;">
                <button class="btn-add" type="button" (click)="addField(catIndex)">Add Item</button>
              </div>
             
            </div>
          </div>
            
          </div>

          
        </div>
        
      </div>

      <button class="btn btn-outline-primary show-btn" type="submit" style="position: fixed;
      right: 15px;font-size: 19px;
      bottom: 340px;">
       <i class="fas fa-eye"></i>&nbsp;  Preview
      </button>
      <!-- <button
        class="btn btn-outline-primary show-btn"
        type="button"
        (click)="backBtn()"
      >
        Back
      </button> -->
    </form>
  </div>

 

<ng-template #content let-modal>
  <div class="modal-header">
    

    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
       
    <h2 style="color: #000;">Preview</h2>
    <div >
      <button class="btn btn-outline-primary" style="width: 100px; margin-bottom: 5%;color: #fff; background: #0056b3;transition: background 0.3s ease; 
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);" (click)="onSave(modal)" type="submit">Save</button>
    </div>
</div>
<div class="modal-body">
  <div style="text-align: center">
    
<div class="table-responsive">
  <table class="table table-bordered table-light">
    <thead class="thead-light">
      <tr>
        <div style="display:flex;justify-content: space-evenly;padding: 10px;">
          <div> Template: {{ form.get('templatename').value }}</div>
            <div>Discount: {{ form.get('discount').value }}</div>
           
        </div>
        
        </tr>
       
    </thead>
    <tbody>
      <tr>
        <td>
          <div  *ngFor="let category of form.get('categories').controls; let catIndex = index">
           
            <div  class="card-header"  (click)="toggleGroundCollapse(catIndex)" [attr.aria-expanded]="!isCollapsed[catIndex]"
            
            aria-controls="collapseExample" style="display: flex;justify-content: space-between;background-color: #b4d9df6b;
           
            font-size: 1.3rem;
        ">
            <div >
              Category  
            </div>
            <div style="font-size: 25px;">
              &#x2304;
            </div>
          </div>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[catIndex]">
            <div class="card-body" style="padding: 0px;">
              <div class="table-responsive">
                <table class="table table-bordered table-info table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <th class="col-2">Product</th>
                      <th class="col-2">Price</th>
                      <th class="col-2">Discount</th>
                      <th class="col-2">Availability</th>
                      <th class="col-2">Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let field of category.get('fields').controls; let fieldIndex = index">
                      <td>{{ field.get('product').value }}</td>
                      <td>{{ field.get('price').value }}</td>
                      <td>{{ field.get('discount').value }}</td>
                      <td>{{ field.get('availability').value ? 'Yes' : 'No' }}</td>
                      <td style="cursor: pointer;" (click)="openImage(field.get('image').value)">
                        <img *ngIf="field.get('image').value" [src]="field.get('image').value" alt="Preview" style="width: 30px; height: 30px;">
                       <span *ngIf="!field.get('image').value">No Image</span> 
                      </td>
                    </tr>
                  </tbody>
                
                </table>
              </div>
            </div>
          </div>

            
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>


  </div>
</div>
  
</ng-template>

 
