import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-more-proyects',
  templateUrl: './more-proyects.component.html',
  styleUrls: ['./more-proyects.component.scss']
})
export class MoreProyectsComponent implements OnInit {
 
  
  images = [
    'assets/images/pricebanner1.jpeg',
    'assets/images/pricebanner2.jpeg',
    'assets/images/pricebanner3.jpeg',
    'assets/images/pricebanner4.jpeg',
    'assets/images/pricebanner5.jpeg',
    'assets/images/pricebanner6.jpeg'
  ];
  chunkedImages: any[] = [];
  constructor(
    private router: Router,
    public analyticsService: AnalyticsService
    ) { }

    ngOnInit() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0)
        });
        this.chunkedImages = this.chunkArray(this.images, 3);
    }
    chunkArray(arr: any[], chunkSize: number): any[] {
      let results = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        results.push(arr.slice(i, i + chunkSize));
      }
      return results;
    }
   
    redirect(route: string, event) {
      const id = event.target.id;
      if(id=='demoLink' || id=='ghLink'){
        return
      }
      window.open(route, '_blank');
    }

}
