<section class="section contact billingArea" id="store">

   
  
    <div class="floating-icon" *ngIf="!ShowFloating" (click)="openPreview(content)">
      <div class="icon-link" >
        <i class="fas fa-shopping-cart"></i>
      </div>
    </div>    
    <div class="container">
  
      <ng-template #content let-modal>
          <div class="modal-header">
              <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                 <span aria-hidden="true">&times;</span>
                </button>
          </div>
          <div *ngIf="success" class="alert alert-success">
            <strong>Success!</strong> your Order Enquiry sent Successfully invoice id #{{randomNum}}. Please check your mail for customer copy!
        </div>
        <div *ngIf="error"  class="alert alert-danger">
          <strong>Sorry!</strong>  There is an issue in sending your Order enquiry. Please try again or contact "+91-96295 86991"
      </div>
          <div class="modal-body" id="invoice-container">
              <div class="container-fluid  mt-4" style="padding: 2px !important;">
                <div class="row">
                  <div class="col-3">
                    <h6>Enquiry No - #{{randomNum}}</h6>
                  </div>
                  <div class="col-6">
                    <div class="row text-center">  
                      <!-- <div class="col-md-auto" style="padding: 0px;">
                        <div class="logo">
                          <img src='assets/viswaiCraclogo.png' width='85px' height="100px" />
                        </div>
                      </div>           -->
                      <div class="col-md-auto">
                        <div><h5>Viswai Crackers</h5></div>
                        <div>
                          2/2323/35, Om Muruga nagar, Viswanatham, Sivakasi-626189
                        </div>
                 
                        <!-- <div>GSTIN</div> -->
                      
                      </div> 
                   
                    </div>
                  </div>
                  <div class="col-3">
                    Total: {{ getTotalPrice() | number:'1.2-2' :'en-US' }}
                    <br>
                    Discount : {{ allTemplate.discount }} %
                  </div>
                </div>
                <div class="row phoneEmail">
                  <div class="col-3"><b>Phone </b>: 96295 86991 
                     <br>
                     <b>Date </b>: {{ todayDate | date:'yyyy-MM-dd' }}
                </div>
                  <div class="col-5"></div>
                  <div class="col-4"> <b>E-mail</b> : viswaicrackers@gmail.com</div>
  
                </div>
        
                  <table class="table table-bordered invoice billingpdf" >
                   
                    <thead>
                      <tr>
                        <th>S.no</th>
                        <th>Code</th>
                        <!-- <th>Category</th> -->
                        <th>{{isDesktopDevice ? 'Product Name' : 'Product'}}</th>
                        <th>Quantity</th>
                        <th>Actual Rate</th>
                        <th>{{isDesktopDevice ? 'Discount Price' : 'Discount'}}</th>
                       
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let category of filteredTemplate; let c = index">
  
                        <tr *ngFor="let item of category.fields; let i = index">
                          <td>{{ calculateSerialNumber(c, i) }}</td>                    <!-- <td>
                            <img *ngIf="item.image" class="avImg cImage" [src]="item.image" alt="Image" (click)="openFullScreen(item.image)">
                            <span *ngIf="!item.image">No Image</span> 
                          </td> -->
                          <!-- <td>{{ item.category }}</td> -->
                          <td >{{ item.id }}</td>    
                          <td>{{ item.product }}</td>
                          <td class="hideInMail" style="width: 10%;">
                            <input
                            id="removetrash"
                            style="width: 100%;"
                            [(ngModel)]="item.count" 
                            (ngModelChange)="updateCountBill(category,item, $event)" 
                              type="number" 
                              name="count" 
                              min="1" 
                              step="1" 
                            value="{{ item.count }}"
                              #countInput
                            />
                          </td>
                          <td class="showInMail">{{item.count }}</td>
                      
                          <td> {{ item.price | number:'1.2-2':'en-US'  }}</td>
                          <td> {{ item.discount | number:'1.2-2':'en-US'  }}</td>
                          
                          <td> {{ (item.discount * item.count) | number:'1.2-2':'en-US'  }}</td>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  <div class="CD">
                    <div class="cd">Customer Details</div>
                    <div>Name: {{customerName}}</div>
                    <div>phone Number: {{customerPhoneNumber}} </div>
                    <div>address: {{customerAddress}} </div>
                  </div>
                 
                </div>
          </div>
          <div class="modal-footer">
            <div class="container">
              <div class="row">
                <div class="col-md-3">
                  <label for="name">Name:</label>
                  <input type="text" id="name" name="name" required [(ngModel)]="customerName" #nameField="ngModel">
                  <div 
                  *ngIf="nameField.touched && nameField.invalid">
                  <small *ngIf="nameField.errors?.required">Name is required</small>
                </div>
                </div>
                <div class="col-md-3">
                  <label for="phoneNumber">Phone Number:</label>
                  <input type="number" id="phoneNumber"  name="number" [(ngModel)]="customerPhoneNumber" #number="ngModel" inputmode="numeric" pattern="^[0-9]*$" maxlength="10" required >
                  <div *ngIf="number.invalid && number.touched">
                    <small *ngIf="number.errors?.required">Number is required.</small>
                    <small *ngIf="number.errors?.pattern">Only numbers are allowed.</small>
                    <small *ngIf="number.errors?.minlength">Number must be 10 digits long.</small>
                    <small *ngIf="number.errors?.maxlength">Number cannot exceed 10 digits.</small>
                  </div>
                </div>
                <div class="col-md-3">
                  <label for="address">Address:</label>
                  <input id="address" name="address"  #address="ngModel" required [(ngModel)]="customerAddress">
                  <div *ngIf="address.invalid && address.touched">
                    <small *ngIf="address.errors?.required">Address is required.</small>
                  </div>
                </div>
                <div class="col-md-3">
                  <label for="email">Email:</label>
                  <input id="email" [(ngModel)]="customerEmail" name="email" #email="ngModel" required  email>
                  <div *ngIf="email.invalid && email.touched">
                    <small *ngIf="email.errors?.required">Email is required.</small>
                    <small *ngIf="email.errors?.email">Invalid email format.</small>
                  </div>
                </div>
                <div *ngIf="success" class="alert alert-success">
                  <strong>Success!</strong> your Order Enquiry sent Successfully invoice id #{{randomNum}}
              </div>
              <div *ngIf="error"  class="alert alert-danger">
                <strong>Sorry!</strong>  There is an issue in sending your Order enquiry. Please try again or contact "+91-96295 86991"
            </div>
            <div *ngIf="downloadSuccess" class="alert alert-success">
              <strong>Success!</strong> your Order Enquiry sent Successfully invoice id #{{randomNum}}
          </div>
              </div>
  
            </div>
            <div *ngIf="showErrorMsg">
              please enter the customer details
            </div>
            <button type="button" class="btn-list"  [ngClass]="{'btn-primary': isLoading, 'btn-outline-dark': !isLoading}"  [disabled]="isLoading" (click)="onDownloadInvoice(content)">
              <span  *ngIf="isLoading">Loading...</span>
              <span *ngIf="!isLoading">Submit</span>
            </button>
            <!-- <button type="button" class="btn btn-outline-dark" (click)="sendEmail()">Sent Mail</button> -->
          </div>
      </ng-template>
      
      
      
      <hr />
      <div class="section-box">
        <div class="storeArea">
          <div class="tabbed-container">
            <h2 class="mhead">Billing</h2>
             <div class="row">
             
             
                  <div class="col-6">
                    <select [(ngModel)]="selectedTemplateId" (change)="onTemplateChange($event.target.value)" class="form-control">
                      <option class="optionCls" *ngFor="let template of templates$ | async" [value]="template.id" [selected]="template.id === selectedTemplateId" [ngStyle]="{'background-color': template.isPrimary ? 'rgba(4, 39, 194, 0.541)' : 'white'}">
                        {{ template.templatename }}
                      </option>
                    </select>
                    <button style="    background-color: rgba(4, 39, 194, 0.541) !important;
                    color: #fff;
                    border-radius: 4px;
                    margin: 10px 0px;
                    padding: .375rem .75rem !important;
                    text-decoration: none;
                    transition: background 0.3s ease; 
                    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);" (click)="editTemplate(selectedTemplateId)" [ngStyle]="{'background-color': selectedTemplate?.isPrimary ? '#28a745' : 'black'}">Select</button>
                              
                  </div>
         
              
              <div class="col-3 preview"> <button type="button"
                  class="btn btn-outline-primary" (click)="openPreview(content)">Preview </button></div>
              <div class="col-3 preview"> <button  type="button"
                  class="btn btn-outline-primary" (click)="clearBtn()">Clear </button>
              </div>
              
            
             </div>
            
             
            <div class="nav nav-tabs">
                <input type="text" class="Search" placeholder="Search..." [(ngModel)]="searchQuery" (ngModelChange)="filterTemplate()" />
  
            </div>
  
            <div class="tab-content">
           
              <div *ngIf="activeTab === 'tab1'" class="">
                <ul>
                  <li class="mainName" *ngFor="let key of filteredSearchTemplate; let i = index">
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      (click)="toggleGroundCollapse(i)"
                      [attr.aria-expanded]="!isGroundCollapsed[i]"
                      aria-controls="collapseExample"
                    >
                      <img class="avImg" src="../../../../assets/images/me.svg" alt="Image">
                      {{ key.name }}
                      <i style="padding: 18px;" class="fa fa-chevron-down"></i>
                    </button>
                
                    <div [ngbCollapse]="isGroundCollapsed[i]" class="card">
                      <div class="card-body">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              
                              <th scope="col">Code</th> 
                              <th scope="col">Image</th>
                              <th scope="col">Product</th>
                              <th scope="col">{{isDesktopDevice ? 'Actual price' : 'A.price'}}</th>
                              <th scope="col discountPrice">{{isDesktopDevice ? 'Discount Price' : 'Discount'}}</th>                            
                              <th scope="col">Quantity</th>
                              <th scope="col">Availability</th>
                            </tr>
                          </thead>
                          <ng-container *ngFor="let subCategory of key.fields; let j = index">
                            <tbody>
                              <tr>
                                <!-- <td>{{ getSerial(i,j,filteredSearchTemplate,key.fields)}}</td>  -->
                             
                                <td>{{ subCategory.id }}</td>
                                <td>
                                  <img *ngIf="subCategory.image" class="avImg cImage" [src]="subCategory.image" alt="Image" (click)="openFullScreen(subCategory.image,subCategory.product)">
                                  <span *ngIf="!subCategory.image">No Image</span> 
                                </td>
                                <td>{{ subCategory.product }}</td>
                                <td class="actualPrice"> {{ subCategory.price }}</td>
                                <td style="width: 15%;" class="discountPrice"> {{ subCategory.discount | number:'1.2-2':'en-US' }}</td>
  
                                <td style="width: 12%;" >
                                  <ng-container *ngIf="subCategory.availability; else unavailable">
                                  <input type="number" class="form-control" [(ngModel)]="subCategory.count" (ngModelChange)="updateCount(i, j, $event)" min="0">
                                  </ng-container>
                                  <ng-template #unavailable>
                                    <i class="fa fa-minus"></i>
                                  </ng-template>
                                </td>
                                <td style="float:left">
                                  <i style="color: green" *ngIf="subCategory.availability" class="fa fa-check"></i>
                                  <i *ngIf="!subCategory.availability"  class="fa fa-times"></i>
                                </td>
                              </tr>
                            </tbody>
                          </ng-container>
                        </table>
                      </div>
                    </div>
                  </li>
                </ul>
               
               
            
              </div>
             
            
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </section>
  