
<section class="stopnow">
  <!-- <div class="row" style="justify-content: center;    margin: 10% 0% 5% 0%;">
    <h2 style="color: #31018a;">Buy Brandred Sivakasi Crackers at viswaicrackers</h2>
    <p class="home-para">We have immense pleasure in welcoming you to visit our mega fireworks showroom located in Sivakasi, where you can directly place your valuable orders and fulfill all your cracker’s requirements at one stop. We are in the cracker industry for the past several years. It's our pride in supplying our esteemed customers with the best quality crackers at the lowest market prices.
<br>
      Each and every moment in life has to be celebrated and we make your celebrations still more memorable with our Premium Garlands, Mind Blowing Chakkars, Flower Pots, and Sky Filling Aerial Shots.</p>
  </div> -->
<div class="row" style="justify-content: center;    margin: 10% 0% 5% 0%;">
  <div class="col-1 d-none d-lg-block"></div>
  <div class="col-md-10 col-lg-10 col-sm-12" >
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"><img src="assets/img/Diwali-1200x834.jpg" width="100%" class="setHeightabout"></div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8" style="display: grid;
      place-items: center; ">
        <div class="mob-buy">
          <h5  style="color: #31018a;    font-size: 2rem;">Buy Brandred Sivakasi Crackers at viswaicrackers</h5>
          <h3 style="font-size: 20px;
    line-height: 28px;">We have immense pleasure in welcoming you to visit our mega fireworks showroom located in Sivakasi, where you can directly place your valuable orders and fulfill all your cracker’s requirements at one stop. 
            <br>
            <br>
            We are in the cracker industry for the past several years. It's our pride in supplying our esteemed customers with the best quality crackers at the lowest market prices.
            
          </h3>
          <div class="row">
            <button class="btn btn-primary btn-home" style=" background: #fd9f06;padding:12px 45px;margin-left: 2%; "routerLink="/store"  >Shop Now</button>
       
          </div>
          </div>
      </div>
      
    </div>
  </div>
  <div class="col-1 d-none d-lg-block"></div>
</div>
</section>


<ngb-carousel [showNavigationArrows]="true"  [interval]="2000" [wrap]="true" [keyboard]="true" [animation]="true"  [pauseOnHover]="true">
  <ng-template ngbSlide *ngFor="let imagePair of chunkedImages; let i = index">
    <div class="row" style="padding: 20px;">
      <div class="col-lg-4 col-md-4" *ngFor="let image of imagePair">
        <!-- col-12 for mobile, col-md-6 for tablets and up -->
        <div class="picsum-img-wrapper">
          <img [src]="image" alt="Image {{ i + 1 }}" class="d-block mx-auto img-fluid">
        </div>
      </div>
    </div>
  </ng-template>
</ngb-carousel>




<section class="section more-proyects">
    <div class="container">
      <div class="section-box">
        <h3 class="section-title" style="color: #31018a;   text-align: center;
    padding-top: 50px;">
          Our Products
        </h3>
        <div class="mt-1">
          <div class="row p-0">
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/chakkar-removebg-preview.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> GROUND CHAKKARS</div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/FLOWERPOT-removebg-preview.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> FLOWER POTS</div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/FF-removebg-preview.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> FANCY FOUNTAINS </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/GIFTBOX-removebg-preview.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> GIFT BOXES</div>
              </div>
            </div>

  
           
          </div>
        </div>
        <div class="mt-0">
          <div class="row p-0">
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/ROCKET-removebg-preview.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> ROCKETS</div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/Chorsa-Gaint-1.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> CHORSA & GAINT CRACKERS</div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/Colour-Matches-1.png" width="100%" class="setHeight" >
                </div>
                <div class="crackerName"> COLOUR MATCHES</div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/SHOTS-removebg-preview.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> SINGLE SOUND CRACKERS</div>
              </div>
            </div>
            
  
           
          </div>
        </div>
        <div class="mt-0">
          <div class="row p-0">
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/SARAM-removebg-preview.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> DEKUXE CRACKERS </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/bomb-removebg-preview.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> AUTOM BOMB</div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/WAALA__1_-removebg-preview.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> WALA CRACKERS</div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-lg-6 col-xl-3 proyect-col" data-aos="fade-up" data-aos-duration="1000">
              <div class="more-proyect-box">
                <div class="row w-100 text-left m-0 p-0">
                 <img src="assets/images/SSSS-removebg-preview.png" width="100%" class="setHeight">
                </div>
                <div class="crackerName"> SPARKLERS</div>
              </div>
            </div>
            
  
           
          </div>
        </div>

        <div class="row" style="margin-bottom: 15px;">
          <div class="col d-flex justify-content-center align-items-centre">
          <button class="btn btn-primary btn-home" style=" background: #fd9f06;margin-top: 4%;padding: 12px 45px;"  routerLink="/store" >View all Products</button>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="container"  style="margin-top: 10%;">
    <h3 class="text-center" style="margin-top: 35px;color: #31018a;">Hear from Our Customers</h3>
<div class="row">
 
  <div class="col-lg-1 d-none d-lg-block"></div>
  <div class="col-lg-10 col-sm-12  happy">
    <carousel>
      <slide>
        <div class="">
          <div class="text-center py-5  text-black">
            <div class="row">
              <div class="col-6 col-sm-6 col-lg-4 col-xl-4" >
                <h2 class="text-black">Vinayaga Moorthi</h2>
              <div class="lead">
                
                <p class="text-black">Really great our Crackers  and shop people's r speak friendly especially night Crackers very well  last year also i buy a Crackers all r very well prices r reasonable Delivery also fast </p>
                <img class="star" src="assets/img/star.png" width="100%">
              </div>
              </div>
              <div class="col-6 col-sm-6 col-lg-4 col-xl-4" >
                <h2>Jagan </h2>
              <div class="lead">
             
                <p>I’m really impressed with the quality of crackers and the friendly service at the shop. The night crackers are especially fantastic! I bought from them last year too, and everything was perfect. </p>
                <img class="star" src="assets/img/star.png" width="100%">
              </div>
              </div>
              <div class="col-6 d-none d-md-block col-lg-4 col-xl-4" >
                <h2>Karthik</h2>
              <div class="lead">
               
                <p>I had a fantastic experience with Viswai Crackers! The staff is super friendly, and the night crackers were a big hit. I purchased from them last year as well, and I’m always satisfied. </p>
                <img class="star" src="assets/img/star.png" width="100%">
              </div>
              </div>
            </div>
           
          </div>
         
        </div>
        
      </slide>
      <slide>
        <div class="">
          <div class="text-center py-5  text-black">
            <div class="row">
              <div class="col-6 col-sm-6 col-lg-4 col-xl-4" >
                <h2 class="text-black">Paul</h2>
              <div class="lead">
               
                <p class="text-black">Viswai Crackers never disappoints! The staff is welcoming, and their night crackers are outstanding.The pricing is reasonable, and the delivery is quick. Highly satisfied with their service!</p>
                <img class="star" src="assets/img/star.png" width="100%">
              </div>
              </div>
              <div class="col-6 col-sm-6 col-lg-4 col-xl-4" >
                <h2>Lakshmi </h2>
              <div class="lead">
             
                <p>Viswai Crackers has been my go-to for celebrations! The team is always friendly, and the night crackers are spectacular. I’ve shopped here before, and I’m always pleased with the quality.</p>
                <img class="star" src="assets/img/star.png" width="100%">
              </div>
              </div>
              <div class="col-6 d-none d-md-block col-lg-4 col-xl-4" >
                <h2> Prabha</h2>
              <div class="lead">
               
                <p>Shopping at Viswai Crackers is always a great experience! The staff is friendly and helpful, and the night crackers are absolutely stunning. The prices are great, and the delivery is prompt. Definitely a go-to for festive needs! </p>
                <img class="star" src="assets/img/star.png" width="100%">
              </div>
              </div>
            </div>
           
          </div>
         
        </div>
        
      </slide>
    
    </carousel>
  </div>
  <div class="col-lg-1 d-none d-lg-block"></div>
</div>
  </section>
  