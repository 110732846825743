import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IpserviceService {
  submittedData: any;
  selectedIDValue: any;

  set submitted(form){
this.submittedData = form;
  }


  get submitted(){
    return this.submittedData;
  }


  set selectedID(id){
    this.selectedIDValue = id;
      }
    
    
      get selectedID(){
        return this.selectedIDValue;
      }

}
