<div class="container-fuild footer" id="footerArea">
  <div class="call-container">
    <div class="container-float">
      <div class="footer-call-content">
          <div class="row">
              <div class="col-12 col-lg-7  col-md-6 d-flex flex-column  align-items-left ">
                  <div class="footer-call-text">
                      <span class="roboto text-white smallfnt">Contact with us</span>
                      <h1 class="acme text-white">For Call &amp; WhatsApp</h1>
                  </div>
              </div>
              <div class=" col-12 col-lg-5 col-md-6 d-flex flex-column  align-items-left">
                  <div class="footer-call-number">
                      <div class="footer-call-number-icon">
                          <img height="120px" width="120px" src="assets/images/support.png" class="img-fluid" alt="Viswai Crackers" title="Malathi Crackers">
                      </div>
                      <div class="footer-call-number-text">
                          <span class="roboto text-white">Call us now</span>
                          <h2 class="acme text-white fnt1">+91 9629 586 991</h2>
                      </div>

                  </div>
              </div>
          </div>
      </div>
  </div>
    </div>

    <div *ngIf="!router.url.includes('admin')  && !router.url.includes('billing') && !router.url.includes('store')"  class="contact-icons">
      <a href="tel:+919629586991" class="icon-call">
        <img  width="150px" src="assets\images\callus.png" class="contactstic" alt="sticker">
      </a>
    </div>
    <div *ngIf="!router.url.includes('admin')  && !router.url.includes('billing') && !router.url.includes('store')"  class="contact-icons">
      <a href="https://wa.me/9629586991" class="whatsapstic" target="_blank">
        <img  width="90px" src="assets\images\watsapp-removebg-preview.png" class="whatsapstic" alt="sticker">
      </a>
    </div>
    <div class="quick" *ngIf="!router.url.includes('admin')  && !router.url.includes('billing') && !router.url.includes('store') " (click)="gotoStore()">
      <img height="190px" width="280px" src="assets/images/quickcopy.png" class="quickstic" alt="sticker">
     </div>


  <div class="row justify-content-center text-align-center fpadding mobilefooter" style="width: 100%;">
    <div class="col-lg-1 d-none d-lg-block"></div>
    <div class="col-6 col-lg-4 col-xl-4 mobileHide">
      <img class="unitrix" src="assets/viswaiCraclogo.png" style="width: 50%" />
      <div style="font-size: 16px">
        Join us in celebrating the simple joy of crunching, and let our crackers
        elevate your every occasion.
      </div>
    </div>
    <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="text-align: left;">
      <div class="head"><strong>Social links :</strong></div>
      <div>
        <a
          href="https://www.instagram.com/p/Cx2PkTEhx1b/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
          target="_blank"
        >
          <u style="font-size: 16px;color:#000;">Instagram</u>
        </a>
      </div>
      <div>
        <a
          href="https://www.facebook.com/ViswaiCrackers/posts/pfbid02c6aSdngo1b2VHc2tECzL1rn4YgJsFmF9D1aZyAtpm7YKGAgKuYUk8tWugir1SqsFl"
          target="_blank"
        >
          <u style="font-size: 16px;color:#000;">Facebook</u>
        </a>
      </div>
      
      <div class="head"><strong>Email : </strong><a style="font-size: 16px;color:#000 !important;" href="mailto:viswaicrackers@gmail.com">viswaicrackers@gmail.com</a></div>
      <div class="head mt-3"><strong>Chat with us :</strong></div>
      <div style="font-size: 16px;"><a style="color:#000 !important;" href="https://wa.me/9629586991">+91 9629 586 991</a></div>
      <div style="font-size: 16px;" ><a style="color:#000 !important;" href="https://wa.me/918667084120">+91 8667 084 120</a></div>
      <div style="font-size: 16px;"><a style="color:#000 !important;" href="https://wa.me/9787043835">+91 97870 43835</a></div>
    </div>
    <!-- <div class="col-xs-12 col-xl-4 col-lg-4 col-md-4 "> 
            <div class="head"><p>viswaicrackers Crackers</p> </div>
            <div> <p><fa-icon [icon]="faLocationDot"></fa-icon>3/766,B-1, Opposite karuman transport,vetrilaiyurani Road Sivakasi,
                Tamil Nadu - 626 189</p></div>                    
            <div> <p> <fa-icon [icon]="faPhone"></fa-icon><a [href]="'tel:' + '7200582944'">7200-582-944</a></p></div>                                 
            <div> <p> <fa-icon [icon]="faPhone"></fa-icon><a [href]="'tel:' + '9047887717'">9047 887717</a></p></div>      
            <div (click)="mailMe()"> <p><fa-icon [icon]="faEnvelope"></fa-icon>viswaicrackers@gmail.com</p></div>                   
        </div>        -->
    <!-- <div class=" col-xs-12 col-xl-2 col-lg-2 col-md-2 "> </div> -->
    <div class="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 " style="text-align: left;">
      <div class="head">
       <strong>Location :</strong>
      </div>
      <div>
        <a
          href="https://www.google.com/maps/place/VISWAI+CRACKERS/@9.4216412,77.7982779,17z/data=!4m6!3m5!1s0x3b06cffe2e6524fb:0x22b673ed49559a76!8m2!3d9.4216359!4d77.8008528!16s%2Fg%2F11l358xcxg?entry=ttu"
          target="_blank"
          ><img src="/assets/qrcode.jpg" height="120px" width="120px" alt=""
        /></a>
      </div>
      <br>
      <div class="head"><strong>Reach us here:</strong></div>
      <p style="font-size: 16px;">2/2323/35, Om Muruga Nagar, Viswanatham, Sivakasi, Tamil Nadu - 626 189.</p>
     
    </div>
    <div class="col-lg-1 d-none d-lg-block"></div>
  
  </div>
</div>

<div class="company">
  <div class="com">
    Copyright ©2005-2023 Viswai Crackers. All rights reserved. | Privacy Policy
    | <span [routerLink]="'/terms'" class="terms">Terms of Service</span>
  </div>
</div>
