<div class="about container">
  <div class="row justify-content-center padding">
  <div class="col-lg-12 col-md-12 about-bg">
    <img src="assets/images/testimonial.gif" alt="bg Image" class="bg-image">
    <div class="banner-content"  data-aos="fade-up" data-aos-duration="1000">
      <h1>About Us</h1>
    </div>
  </div>

 
    <div class="col-lg-6 col-md-12" >
      <div class="left" data-aos="fade-up" data-aos-duration="1000">
      <img class="about-logo img-fluid "  src="assets/viswaiCraclogo.png" style="width: 60%" />
      <div class="left-content "data-aos="fade-up" data-aos-duration="1000" >
        Ignite your celebrations with Viswai Crackers! For over several years, we've been lighting up moments of joy with our vibrant and high-quality fireworks. From dazzling crackers to sparkling sparklers, we offer a wide range of products that add excitement and color to any occasion. Join our community of happy customers and make your festivities unforgettable with Viswai Crackers. Explore our collection today and let the celebration begin!
        <br>
        <br>
        <div class="div-btn-banner">
          <a  routerLink="/store" class="btn btn-outline-primary">
              View Products
          </a>
      </div>
      </div>
      
     
    </div>
    </div>
    <div class="col-lg-6 col-md-12" >
      <div class="container-right">
        <div class="right">
          <div class="right-content">
                <h3 data-aos="fade-up" data-aos-duration="1000">Viswai Crackers !</h3>
                <br>
               <h6 data-aos="fade-up" data-aos-duration="1000">Viswai CRACKERS  is a family of delightful cracker brands and a collection of select joyspreading and safe fireworks</h6>
          </div>
          <img class="right-logo img-fluid" src="assets/images/aboutimg.webp" style="width: 40%">
        </div>
      </div>
    
    </div>
  </div>
  

