
 <!-- Fullscreen Image Overlay -->
 <div class="image-overlay" *ngIf="showFullscreen">
  <div class="overlay-content">
    <span class="close-button" (click)="closeImage()">&times;</span>
    <img
      [src]="fullscreenImage"
      alt="Fullscreen Images"
      class="fullscreen-image"
    />
  </div>
</div>

<div *ngIf=" submittedData" style="text-align: center">
    <h2>Preview</h2>
   
<div class="table-responsive">
  <table class="table table-bordered table-light">
    <thead class="thead-light">
      <tr>
        <div style="display:flex;justify-content: space-evenly;padding: 10px;">
          <div> Template: {{ submittedData.templatename }}</div>
            <div>Discount: {{ submittedData.discount }}</div>
        </div>
        
        </tr>
       
    </thead>
    <tbody>
      <tr>
        <td>
          <div  *ngFor="let category of submittedData.categories; let catIndex = index">
           
            <div  class="card-header"  (click)="toggleGroundCollapse(catIndex)" [attr.aria-expanded]="!isCollapsed[catIndex]"
            
            aria-controls="collapseExample" style="display: flex;justify-content: space-between;background-color: #b4d9df6b;
           
            font-size: 1.3rem;
        ">
            <div >
              Category {{catIndex + 1}}
            </div>
            <div style="font-size: 22px;">
              ⮟
            </div>
          </div>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[catIndex]">
            <div class="card-body" style="padding: 0px;">
              <div class="table-responsive">
                <table class="table table-bordered table-info table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <th class="col-2">Product</th>
                      <th class="col-2">Price</th>
                      <th class="col-2">Discount</th>
                      <th class="col-2">Availability</th>
                      <th class="col-2">Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let field of category.fields; let fieldIndex = index">
                     
                      <td>{{ field.product}}</td>
                      <td>{{ field.price}}</td>
                      <td>{{ field.discount}}</td>
                      <td>{{ field.availability? 'Yes' : 'No' }}</td>
                      <td style="cursor: pointer;" (click)="openImage(field.image)">
                        <span *ngIf="!field.image">no image</span>
                        <img *ngIf="field.image" [src]="field.image" alt="Preview" style="width: 30px; height: 30px;">
                      </td>
                    </tr>
                  </tbody>
                
                </table>
              </div>
            </div>
          </div>

            
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="row justify-content-center">
  <button class="btn btn-outline-primary" style="width: 400px; margin-bottom: 5%;" (click)="onSave()" type="submit">Save</button>
</div>
  </div>