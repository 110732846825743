import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  hideStore = false;
  constructor(public router:Router) { }

  
  
  mailText:string = "";

  ngOnInit(): void {
  }

  gotoStore(){
    
    this.router.navigate(['/store']).then(()=>{
    this.hideStore= true;
    }).catch(err => {
      console.error('Navigation failed', err);
    });
  }
  mailMe(){
    this.mailText = "mailto:viswaicrackers@gmail.com+?subject=Enquire&body=type your requirement"; // add the links to body
    window.location.href = this.mailText;
  }
  scrollToProduct() {
    

    const contentElement = document.getElementById('product'); 
    if (contentElement) {
      window.scrollTo({
        behavior: 'smooth',
        top: contentElement.offsetTop
      });
    }
  }
  scrollToGallery() {

    const contentElement = document.getElementById('Gallery'); 
    if (contentElement) {
      window.scrollTo({
        behavior: 'smooth',
        top: contentElement.offsetTop - 90
      });
    }
  }

  scrollToHome() {
    window.scrollTo({ behavior: 'smooth',top:0});
  }
}
