import { Component } from "@angular/core";
import { ModalDismissReasons, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { IpserviceService } from "src/app/services/ipservice/ipservice.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { DeviceDetectorService } from 'ngx-device-detector';
import { TemplateManagerService } from "src/app/services/fire/template.service";
import { FullScreenImageComponent } from "../../full-screen-image/full-screen-image.component";

@Component({
  selector: "app-store",
  templateUrl: "./store.component.html",
  styleUrls: ["./store.component.scss"],
})
export class StoreComponent {
  collapsed: true;
  deviceInfo = null;
  ShowFloating: boolean = false;
  isGroundCollapsed: boolean[] = [];
  isSkyCollapsed: boolean[] = [];
  isFancyCollapsed: boolean[] = [];
  activeTab: string = "tab1";
  groundCrackersKeys: string[];
  fireworksData: any;
  showErrorMsg: boolean = false;
  GroundCrackers: any;
  randomNum: any;
  skyCrackers: any;
  closeResult = "";
  fancyCrackers: any;
  success: boolean = false;
  error: boolean = false;
  isDesktopDevice:any;
  skyCrackersKeys: string[];
  fancyCrackersKeys: string[];
  customerName: string = "";
  customerPhoneNumber: string = "";
  customerAddress: string = "";
  // customerEmail: string = "";
  isSendDisabled: boolean = true;
  downloadSuccess: boolean = false;
  modalRef: NgbModalRef;
  isLoading: boolean = false;


  primaryTemplate: any;
  filteredTemplate: any;
  allTemplate: any;
  filteredSearchTemplate: any;
  searchQuery: any= '';
  filterTotalPrice: number;
  todayDate: Date;
 
  
  

  constructor(
    private ipserviceService: IpserviceService,
    private modalService: NgbModal,
    private http: HttpClient,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private templateManagerService: TemplateManagerService
    
  ) {
    this.todayDate = new Date(); 
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    

    this.getPrimaryTemplate();

  }
 

  // getSerial(i,j,pri,sec){
    
  //   this.initialCount;
  //   const back = 0
  //   let prilength = sec.length;
  //   debugger
  //   var result = j+ 1+ this.initialCount;
  //   if(sec.length == j+1){
  //     this.initialCount = prilength +this.initialCount;
  //   }
    
  //   return result;
  


  // }
 
  openFullScreen(imageUrl: string,imageName:string): void {
 
    if(imageUrl != '' && imageUrl != null){
       this.modalRef = this.modalService.open(FullScreenImageComponent, { size: 'lg' });
      this.modalRef.componentInstance.imageUrl = imageUrl;
      this.modalRef.componentInstance.imageName = imageName;
    }
    
  }
  

  getPrimaryTemplate() {
    this.templateManagerService.getPrimaryTemplateFromFB().subscribe(
      template => {
        this.allTemplate = template;
        this.primaryTemplate = template.categories.map(category => ({
          ...category,
          fields: category.fields.map(field => ({
            ...field,
            count: 0,
            category: category.name
          }))
        }));
        console.log('Primary Template:', this.primaryTemplate);
        this.filteredSearchTemplate = this.primaryTemplate
      },
      error => {
        console.error('Error fetching primary template:', error);
      }
    );
  }

   // Method to filter the template based on search query
   filterTemplate() {
    if (this.searchQuery.trim() === '') {
      this.filteredSearchTemplate = [...this.primaryTemplate]; // If search query is empty, show all items
    } else {
      this.filteredSearchTemplate = this.primaryTemplate.map(category => {
        return {
          ...category,
          fields: category.fields.filter(item => 
            category.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.product.toLowerCase().includes(this.searchQuery.toLowerCase())  ||
            item.id.toString().includes(this.searchQuery) 
          )
        };
      }).filter(category => category.fields.length > 0); // Remove empty categories
    }
  }

  updateCount(cindex:number, findex: number, count: number) {
    count = count ?? 0;
    this.primaryTemplate[cindex].fields[findex].count = count;
  }


  updateCountBill(cindex:number, findex: number, count: number) {
    count = count ?? 0;
    this.filteredTemplate[cindex].fields[findex].count = count;
  }

 

  showTab(tabName: string) {
    this.activeTab = tabName;
  }

  getTotalPrice(): number {
    
    let totalPrice = 0;
   
    for (let category of this.filteredTemplate) {
      for (let item of category.fields) {
        if (item.discount && item.count) {
          let itemPrice = item.discount * item.count;
          itemPrice = Math.round(itemPrice * 100) / 100;
          totalPrice += itemPrice;
        }
      }
    }
    totalPrice = Math.round(totalPrice * 100) / 100;
    this.filterTotalPrice = totalPrice;
    return totalPrice;
  }




  getinvoiceElementID() {
    const element = document.getElementById("invoice-container") as HTMLElement;
    element.style.border = "none";

    return element;
  }

  onDownloadInvoice(content: any): void {
   this.isLoading = true;

    // Use `setTimeout` to ensure loading state is rendered before starting async operation
    setTimeout(() => {
      this.downloadInvoiceAsPDF(content).finally(() => {
        this.isLoading = false; // Reset loading state once done
      });
    }, -1000);
  }

  downloadInvoiceAsPDF(content: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const data: any = this.getinvoiceElementID();
      const imgWidth = 595; // A4 page width in pixels
      const pageHeight = 842; // A4 page height in pixels
      const padding = 20; // White space at the bottom of each page in pixels
    
      html2canvas(data).then(async canvas => {
        const imgData = canvas.toDataURL('image/jpeg', 0.6);
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const pdf = new jspdf.jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: [imgWidth, pageHeight]
        });
    
        let y = 0;
        let pageNumber = 1;
    
        while (y + imgHeight > pageHeight - padding) {
          pdf.addImage(imgData, 'JPEG', 0, y, imgWidth, imgHeight);
          y -= pageHeight; // Move to the next page
          pdf.addPage();
          pageNumber++;
        }
    
        pdf.addImage(imgData, 'JPEG', 0, y, imgWidth, imgHeight); // Add the remaining content
    
        const currentDateTime = moment().format('DD/MM/YYYY HH:mm:ss');
        const currentDateTimeStr = currentDateTime.replace(/[^a-zA-Z0-9]/g, '');
        pdf.save('invoice_' + currentDateTimeStr + '.pdf');
        
        await this.sendEmail();
        this.saveDataFire(imgData);  

        resolve(); // Resolve the promise after completing the PDF generation
      }).catch(error => {
        console.error('Error generating PDF:', error);
        reject(error); // Reject the promise in case of an error
      });
    });
  }
  
  

  saveDataFire(content){
    

    let saveJson = {
      data: this.filteredTemplate,
      customerName:this.customerName,
      customerPhoneNumber:this.customerPhoneNumber,
      customerAddress : this.customerAddress,
      // customerEmail: this.customerEmail,
      filterTotalPrice:this.filterTotalPrice
    }

    this.templateManagerService.saveStoreData(saveJson)
    .then(() => {
  
      console.log('Data saved successfully');
      this.modalService.dismissAll();
      
    })
    .catch((error) => {
      console.error('Error saving data: ', error);
    });


  }

  checkFields() {
    // Check if all three fields are filled
    const result =
      this.customerName != "" &&
      this.customerPhoneNumber != "" &&
      this.customerAddress != "";
      // this.customerEmail != "";

    return result;
  }

  async getInvoiceAsBase64(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const data: any = this.getinvoiceElementID();
      const imgWidth = 595; // A4 page width in pixels
      const pageHeight = 842; // A4 page height in pixels
      const padding = 20; // White space at the bottom of each page in pixels
  
      html2canvas(data, {
        backgroundColor: '#ffffff', // Set the background color to white
      }).then(canvas => {
        const imgWidth = 595; // A4 width in pixels
        const pageHeight = 842; // A4 height in pixels
        const padding = 5; // Padding to avoid content near the edge
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate image height while maintaining aspect ratio
        const content = canvas.toDataURL('image/jpeg', 1.0); // Use higher quality (1.0) for better output
  
        let pdf = new jspdf.jsPDF({
          orientation: 'portrait', // Portrait mode
          unit: 'px', // Units in pixels
          format: [imgWidth, pageHeight] // A4 dimensions
        });
  
        let y = 0; // Initial Y-coordinate
        let position = 0; // Tracks the position of content to be drawn on the current page
  
        while (position < canvas.height) {
          const pageCanvas = document.createElement('canvas');
          pageCanvas.width = canvas.width;
          pageCanvas.height = pageHeight * (canvas.width / imgWidth); // Set height proportionally to imgWidth
  
          const ctx = pageCanvas.getContext('2d');
          ctx.fillStyle = '#ffffff'; // Set background fill color to white
          ctx.fillRect(0, 0, pageCanvas.width, pageCanvas.height); // Fill the entire canvas with white
          ctx.drawImage(canvas, 0, position, canvas.width, pageCanvas.height, 0, 0, pageCanvas.width, pageCanvas.height);
  
          const pageImg = pageCanvas.toDataURL('image/jpeg', 1.0);
          pdf.addImage(pageImg, 'JPEG', 0, 0, imgWidth, pageHeight);
  
          position += pageCanvas.height; // Move to the next section of the canvas
  
          if (position < canvas.height) {
            pdf.addPage(); // Add a new page if there's more content
          }
        }
  
        const pdfData = pdf.output("datauristring"); // Generate the PDF as a data URI string
        resolve(pdfData); // Resolve with the generated PDF data URI
      }).catch(error => {
        reject(error);
      });
    });
  }
  

  generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Example usage
  getRandomNumber() {
    this.randomNum = this.generateRandomNumber(1, 10000); // Generate a random number between 1 and 100
    console.log("Random Number:", this.randomNum);
  }

  async sendEmail() {
    const removeInvoiceElements =
      document.getElementsByClassName("removetrash");

    for (let i = 0; i < removeInvoiceElements.length; i++) {
      (removeInvoiceElements[i] as HTMLElement).style.display = "none";
    }


    if (!this.checkFields()) {
      this.showErrorMsg = true;
    } else {
      const cssStyles = `
      <style>
        img {
          display: none;
        }
        table {
          padding: 0 5px;
          padding-top: 20px;
        }
        tr {
          padding: 20px 10px;
        }
        td {
          padding: 10px 25px;
        }
        th {
          text-align: left;
          padding-left: 25px;
        }
        .row {
          padding-left: 30px;
        }
        .CD {
          padding: 10px 30px;
        }
        .cd {
          font-weight: 600;
          padding-bottom: 5px;
        }
        tr {
          text-align: left !important;
        }
        .hideInMail{
          display: none;
        }
        .showInMail{
          display: block;
        }
      </style>
    `;

      this.showErrorMsg = false;

      const from = "infounitrix@gmail.com";

      let base = await this.getInvoiceAsBase64();

      let to = ["viswaicrackers@gmail.com"];
      
      let subject = "Order Received! Order ID: " + this.randomNum;
      let text =
        "Order Received from Customer Name " +
        this.customerName +
        " Customer Mobile Number " +
        this.customerPhoneNumber +
        " Delivery Address: " +
        this.customerAddress;

      const element = this.getinvoiceElementID();
      const elementHtml = element.innerHTML;
      const fullHtml = `<html><head>${cssStyles}</head><body>${elementHtml}</body></html>`;

      const data = {
        to: to,
        subject: subject,
        text: text,
        from:  from,
        base: base,
        html: fullHtml
      };
  
      // Define the URL of your Cloud Function
      const cloudFunctionUrl = 'https://us-central1-cherishcracker.cloudfunctions.net/sendEmail';
  
      this.http.post(cloudFunctionUrl, data)
        .subscribe(
          (response) => {
            this.success = true;
            setTimeout(() => {
              this.success = false;
            }, 1000);
            console.log('Email sent successfully', response);
          },
          (error) => {
            this.error = true;
            setTimeout(() => {
              this.error = false;
            }, 1000);
            console.error('Error sending email:', error);
          }
        );


    }
  }

  calculateSerialNumber(c: number, i: number): number {
    let serialNumber = 0;
    for (let j = 0; j < c; j++) {
      serialNumber += this.filteredTemplate[j].fields.length;
    }
    return serialNumber + i + 1;
  }


  trash(id) {
 
    const existingIndex = this.filteredTemplate.findIndex((item) => item.id === id);

    if (existingIndex !== -1) {
      this.filteredTemplate.splice(existingIndex, 1);
    }
  }

  filterPrimaryTemplate() {
    let arrayTemp = [];
  
    // Iterate over each category in primaryTemplate
    this.primaryTemplate.forEach(category => {
      // Filter fields within each category where count is not equal to 0
      let filteredFields = category.fields.filter(field => field.count !== 0);
  
      // If filtered fields exist (i.e., not empty), add category with filtered fields to arrayTemp
      if (filteredFields.length > 0) {
        arrayTemp.push({
          ...category,
          fields: filteredFields // Replace original fields with filtered fields
        });
      }
    });
  
    this.filteredTemplate = arrayTemp;
  }
  


  openPreview(content:any) {
    this.ShowFloating = true;
    this.getRandomNumber();
    this.filterPrimaryTemplate();
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        windowClass: "modal-xl",
      })
      .result.then(
        (result) => {
          
          this.ShowFloating = false;
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          
          this.ShowFloating = false;
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  toggleGroundCollapse(index: number) {
    this.isGroundCollapsed[index] = !this.isGroundCollapsed[index];
  }
  toggleSkyCollapse(index: number) {
    this.isSkyCollapsed[index] = !this.isSkyCollapsed[index];
  }
  toggleFancyCollapse(index: number) {
    this.isFancyCollapsed[index] = !this.isFancyCollapsed[index];
  }

  getJson() {
    this.GroundCrackers = this.fireworksData.GroundCrackers;
    this.groundCrackersKeys = Object.keys(this.GroundCrackers);

    // Initialize isCollapsed array with false values
    // this.isGroundCollapsed = new Array(this.groundCrackersKeys.length).fill(
    //   true
    // );

    this.skyCrackers = this.fireworksData.SkyCrackers;
    this.skyCrackersKeys = Object.keys(this.skyCrackers);

    // Initialize isCollapsed array with false values
    // this.isSkyCollapsed = new Array(this.skyCrackersKeys.length).fill(true);

    this.fancyCrackers = this.fireworksData.FancyCrackers;
    this.fancyCrackersKeys = Object.keys(this.fancyCrackers);

    // Initialize isCollapsed array with false values
    // this.isFancyCollapsed = new Array(this.fancyCrackersKeys.length).fill(true);
  }
  getMain(value) {
    let name = "";
    if (value == 0) {
      name = "SkyCrackers";
    } else if (value == 1) {
      name = "FancyCrackers";
    } else if (value == 2) {
      name = "GroundCrackers";
    } else if (value == 3) {
      name = "Gift Box";
    }
    return name;
  }



  clearBtn() {
    
   
    this.ngOnInit()
  }
  getUniqueIdentifier(key, subCategory) {
    // You can concatenate key and subCategory values to create a unique identifier
    // For example:
    return key + "_" + subCategory.key;
  }
}
