import { Component, HostListener, TemplateRef, ViewChild } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/compat/firestore";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { TemplateManagerService } from "src/app/services/fire/template.service";
import { IpserviceService } from "src/app/services/ipservice/ipservice.service";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
})
export class AdminComponent {
  form: FormGroup;
  isCollapsed: boolean[] = [];
  isEditCollapsed: boolean[] = [];
  transformedData: any;
  templatesCollection: AngularFirestoreCollection<any>;
  templates$: Observable<any[]>;
  dataValue: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedItemId: string | undefined;
  showCreateTempate: boolean = false;
  uploadedData: any;
  submittedData: any;
  selectedTemplateId: string | null = null;
  fullscreenImage: string | null = null;
  showFullscreen: boolean = false;
  BillingData: any[];
  StoreData: any[];
  modalRefPreview: NgbModalRef;
  @ViewChild("content") contentTemplate: TemplateRef<any>;
  fresh: boolean = true;

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private modalService: NgbModal,
    private templateManagerService: TemplateManagerService,
    private ipservice: IpserviceService,
    private router: Router
  ) {
    this.templatesCollection = this.afs.collection("templates");
    this.form = this.fb.group({
      isPrimary: [false],
      templatename: ["", Validators.required],
      discount: [""],
      categories: this.fb.array([]),
    });
    this.addCategory();
  }

  @HostListener("window:keydown.esc")
  @HostListener("document:click", ["$event"])
  onGlobalClick(event: MouseEvent) {
    if (this.fullscreenImage && !this.isClickInsideOverlay(event)) {
      //this.closeImage();
    }
  }

  private isClickInsideOverlay(event: MouseEvent): boolean {
    const overlay = document.querySelector(".image-overlay");
    return overlay ? overlay.contains(event.target as Node) : false;
  }

  ngOnInit() {
    this.getTab1();
    this.getTab2();
    window.scrollTo(0, 0);
    this.addField(0);
    this.getCollection();
    this.templates$ = this.dataValue.asObservable();

    // Add form listeners for discount calculation
    this.form.get("discount").valueChanges.subscribe(() => {
      this.calculateDiscounts();
    });

    this.form.get("categories").valueChanges.subscribe(() => {
      this.calculateDiscounts();
    });
  }

  toggleGroundCollapse(index: number) {
    this.isCollapsed[index] = !this.isCollapsed[index];
  }
  toggleEditCollapse(index: number) {
    this.isEditCollapsed[index] = !this.isEditCollapsed[index];
    this.fresh = false;
  }
  onCollapseBodyClick(event: MouseEvent) {
    event.stopPropagation();
  }

  getTab1() {
    this.templateManagerService.getBillingDataCollection().subscribe((data) => {
      this.BillingData = data;
    });
  }

  getTab2() {
    this.templateManagerService.getStoreDataCollection().subscribe((data) => {
      this.StoreData = data;
    });
  }

  calculateDiscounts() {
    const categories = this.form.get("categories") as FormArray;
    categories.controls.forEach((category, catIndex) => {
      const fields = (category.get("fields") as FormArray).controls;

      if (this.fresh) {
        this.isEditCollapsed[catIndex] = true;
      }

      fields.forEach((field, fieldIndex) => {
        this.getCategoryDiscount(catIndex, fieldIndex);
      });
    });
  }

  openImage(imageUrl: string) {
    this.fullscreenImage = imageUrl;
    this.showFullscreen = true;
  }

  closeImage() {
    this.fullscreenImage = null;
    this.showFullscreen = false;
  }

  getCategoryDiscount(catIndex: number, fieldIndex: number): void {
    const categories = this.form.get("categories") as FormArray;
    const category = categories.at(catIndex) as FormGroup;
    const fields = category.get("fields") as FormArray;
    const field = fields.at(fieldIndex) as FormGroup;

    const price = field.get("price").value;
    const discount = this.form.get("discount").value;

    if (!price || !discount || isNaN(price) || isNaN(discount)) {
      field.get("discount").setValue(0, { emitEvent: false });
      return;
    }

    const discountAmount = (price * discount) / 100;
    const discountedPrice = price - discountAmount;

    field.get("discount").setValue(discountedPrice, { emitEvent: false });
  }

  onImageSelected(
    event: any,
    catIndex: number,
    fieldIndex: number
  ): Promise<string> {
    const file = event.target.files[0];
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const filePath = `images/${file.name}`;
      const fileRef = ref(storage, filePath);
      const uploadTask = uploadBytesResumable(fileRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          switch (snapshot.state) {
            case "paused":
              break;
            case "running":
              break;
          }
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((downloadURL) => {
              this.getCategoryFields(catIndex)
                .at(fieldIndex)
                .get("image")
                .setValue(downloadURL);
              resolve(downloadURL);
            })
            .catch((error) => {
              reject(error);
            });
        }
      );
    });
  }

  selectItem(itemId: any) {
    this.selectedItemId = itemId;
  }

  setPrimary() {
    if (this.selectedItemId !== undefined) {
      console.log("Selected Item ID:", this.selectedItemId);
      this.selectedTemplateId = this.selectedItemId;

      const batch = this.afs.firestore.batch();
      const templatesCollectionRef = this.templatesCollection.ref;

      // Get all templates
      templatesCollectionRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.id === this.selectedTemplateId) {
              batch.update(doc.ref, { isPrimary: true });
            } else {
              batch.update(doc.ref, { isPrimary: false });
            }
          });

          // Commit the batch
          return batch.commit();
        })
        .then(() => {
          alert("Primary template updated successfully.");
          console.log("Primary template updated successfully.");
        })
        .catch((error) => {
          console.error("Error updating templates: ", error);
        });
    }
  }

  get isFormValid(): boolean {
    return this.form.valid;
  }

  get categories(): FormArray {
    return this.form.get("categories") as FormArray;
  }

  addCategory() {
    const categoryGroup = this.fb.group({
      name: ["", Validators.required],
      fields: this.fb.array([]),
    });
    this.categories.push(categoryGroup);
  }

  removeCategory(index: number) {
    this.categories.removeAt(index);
  }

  getCategoryFields(categoryIndex: number): FormArray {
    return this.categories.at(categoryIndex).get("fields") as FormArray;
  }

  getRandomThreeDigitNumber(): number {
    return Math.floor(100 + Math.random() * 900);
  }

  addField(categoryIndex: number) {
    const fieldGroup = this.fb.group({
      product: ["", Validators.required],
      price: [0, Validators.required],
      discount: [0, Validators.required], // This will hold the discounted price
      availability: [false],
      image: [""],
      id:this.getRandomThreeDigitNumber()
    });
    this.getCategoryFields(categoryIndex).push(fieldGroup);
  }

  removeField(catIndex: number, fieldIndex: number) {
    this.getCategoryFields(catIndex).removeAt(fieldIndex);
  }

  moveCategoryUp(index: number) {
    if (index === 0) return;
    const items = this.categories;
    const item = items.at(index);
    items.removeAt(index);
    items.insert(index - 1, item);
  }

  moveCategoryDown(index: number) {
    if (index === this.categories.length - 1) return;
    const items = this.categories;
    const item = items.at(index);
    items.removeAt(index);
    items.insert(index + 1, item);
  }

  moveFieldUp(catIndex: number, fieldIndex: number) {
    if (fieldIndex === 0) return;
    const items = this.getCategoryFields(catIndex);
    const item = items.at(fieldIndex);
    items.removeAt(fieldIndex);
    items.insert(fieldIndex - 1, item);
  }

  moveFieldDown(catIndex: number, fieldIndex: number) {
    const items = this.getCategoryFields(catIndex);
    if (fieldIndex === items.length - 1) return;
    const item = items.at(fieldIndex);
    items.removeAt(fieldIndex);
    items.insert(fieldIndex + 1, item);
  }

  onSubmit() {
    if (this.form.valid) {
      this.submittedData = this.form.value;
      this.openModal(this.contentTemplate);
    }
  }

  openModal(content) {
    this.modalRefPreview = this.modalService.open(content, {
      centered: true,
      size: "lg",
    });
    this.modalRefPreview.close;
    // Optional: handling modal result
    this.modalRefPreview.result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`Dismissed with: ${reason}`);
      }
    );
  }

  backBtn() {
    this.showCreateTempate = !this.showCreateTempate;
  }

  editTemplate(template: any) {
    this.showCreateTempate = true;
    this.selectedTemplateId = template.id;
    this.form.patchValue({
      templatename: template.templatename,
      discount: template.discount,
    });

    while (this.categories.length !== 0) {
      this.categories.removeAt(0);
    }

    template.categories.forEach((category) => {
      const categoryGroup = this.fb.group({
        name: [category.name, Validators.required],
        fields: this.fb.array([]),
      });

      category.fields.forEach((field) => {
        const fieldGroup = this.fb.group({
          product: [field.product, Validators.required],
          price: [field.price, Validators.required],
          discount: [field.discount, Validators.required],
          availability: [field.availability],
          count: [0],
          image: [field.image],
          id : [field.id]
        });
        (categoryGroup.get("fields") as FormArray).push(fieldGroup);
      });

      this.categories.push(categoryGroup);
    });
  }

  duplicateData(template: any) {
    const duplicateTemplateRef = this.templatesCollection.doc(); // Creates a new document reference
    
    // Check if the template name already ends with " - Copy" or " - Copy X"
  let newTemplateName = template.templatename;
  const copySuffix = " - Copy";
  
  if (newTemplateName.endsWith(copySuffix)) {
    // Extract the current version number if it exists
    const versionMatch = newTemplateName.match(/ - Copy (\d+)$/);
    
    if (versionMatch) {
      // Increment the version number
      const versionNumber = parseInt(versionMatch[1], 10) + 1;
      newTemplateName = newTemplateName.replace(/ - Copy (\d+)$/, ` - Copy ${versionNumber}`);
    } else {
      // First copy with a number, add " - Copy 2"
      newTemplateName = `${newTemplateName} 2`;
    }
  } else {
    // No " - Copy" suffix, add it
    newTemplateName = `${newTemplateName}${copySuffix}`;
  }

    const duplicateTemplate = {
      ...template,
      id: duplicateTemplateRef.ref.id, // Assigns the Firebase generated ID,
      templatename: newTemplateName
    };
  
    this.templatesCollection.doc(duplicateTemplate.id).set(duplicateTemplate)
      .then(() => {
        this.showCreateTempate = true;
        this.selectedTemplateId = duplicateTemplate.id;
  
        this.form.patchValue({
          templatename: duplicateTemplate.templatename,
          discount: duplicateTemplate.discount
        });
  
        // Clear existing categories
        while (this.categories.length !== 0) {
          this.categories.removeAt(0);
        }
  
        // Add categories and fields to form
        duplicateTemplate.categories.forEach(category => {
          const categoryGroup = this.fb.group({
            name: [category.name, Validators.required],
            fields: this.fb.array([]),
          });
  
          category.fields.forEach((field) => {
            const fieldGroup = this.fb.group({
              product: [field.product, Validators.required],
              price: [field.price, Validators.required],
              discount: [field.discount, Validators.required],
              availability: [field.availability],
              count: [0],
              image: [field.image],
              id:[field.id]
            });
            (categoryGroup.get("fields") as FormArray).push(fieldGroup);
          });
  
          this.categories.push(categoryGroup);
        });
  
        this.loadTemplates(); 
        alert('Template duplicated successfully');
      })
      .catch(error => {
        console.error('Error duplicating template: ', error);
        alert('Error duplicating template');
      });
  }
  
  loadTemplates() {
    this.templates$ = this.templatesCollection.valueChanges();
  }
  

  onSave(modal) {
    if (this.selectedTemplateId) {
      // Update existing template
      this.templatesCollection.doc(this.selectedTemplateId.toString()).update(this.form.value)
        .then(() => {
          this.handleSaveSuccess(modal);
        })
        .catch(error => {
          console.error('Error updating data: ', error);
          alert('Error updating data');
        });
    } else {
      this.templatesCollection.add(this.form.value)
        .then(() => {
          this.handleSaveSuccess(modal);
        })
        .catch(error => {
          console.error('Error saving data: ', error);
          alert('Error saving data');
        });
    }
  }

  private handleSaveSuccess(modal) {
    this.form.reset();
    this.transformedData = {};
    this.showCreateTempate = false;
    this.submittedData = [];
    alert('Data uploaded successfully');
    modal.close('Save click');
  
}

deleteTemplate(template: any): Promise<void> {
  const userResponse =  prompt('Do you want to delete? Type "adminPassword"');
  const pwd = localStorage.getItem('adminPwd')
  if (userResponse.trim().toLowerCase() === pwd) {
    debugger
    const result = this.templatesCollection.doc(template.id).delete();
    this.ngOnInit();
    alert("deleted successfully");
    return result;
  } else {
    // User clicked "No"
    alert('sry something went wrong!')
  }
}


  saveToFirestore(value: any) {
    this.afs
      .collection("templates")
      .add(value)
      .then(() => {
        this.form.reset();
        this.transformedData = {};
        this.showCreateTempate = false;
        alert("Data uploaded successfully");
      })
      .catch((error) => {
        console.error("Error saving data: ", error);
      });
  }

  getCollection() {
    this.templatesCollection
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const id = a.payload.doc.id;
            const data = { id, ...a.payload.doc.data() };
            return data;
          });
        }),
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe(
        (data) => {
          this.dataValue.next(data);
        },
        (error) => {
          console.error("Error fetching collection:", error);
        }
      );
  }

  CreateNew() {
    // Reset the form to its initial state
    this.form.reset();

    // Clear any existing categories
    while (this.categories.length !== 0) {
      this.categories.removeAt(0);
    }

    // Set the default state for the showCreateTemplate flag and selectedTemplateId
    this.showCreateTempate = true;
    this.selectedTemplateId = null;

    // Optionally, add a new category if desired

    this.addCategory();
    this.addField(0);
  }
}
