<div class="add">
  <div class="moving-text">
    The 2024 Diwali Sale is now live! Shop early to take advantage of the best
    discounts and offers. Wishing you a joyful and prosperous Diwali!
    <!-- Welcome to viswaicrackers! -  <span class="phone-symbol">📞</span> +91 9629 586 991  <span class="phone-symbol">📞</span>+91 8667 084 120  <span class="phone-symbol">📞</span>+91 97870 43835 -->
  </div>

</div>
<nav
  [@animateMenu]
  class="navbar navbar-expand-lg main-navbar on-top"
  [class.nav-shadow]="this.pageYPosition > 0"
>
 
    <a
      (click)="redirectTo('/home')"
      class="navbar-brand brandname"
      style="padding-left: 2rem; color: #fff"
    >
      <img src="assets/viswaiCraclogo.png" width="90px" height="75px" /> Viswai
      Crackers
    </a>
    <ul ngbNav #nav="ngbNav" class="menu-ul">
      <li ngbNavItem>
        <a ngbNavLink (click)="redirectTo('/home')">
          <span class="nav-number"></span>
          <span class="underline nav-text">HOME</span>
        </a>
        <ng-template ngbNavContent>Home content</ng-template>
      </li>

      <li ngbNavItem>
        <a ngbNavLink (click)="redirectTo('/about')">
          <span class="nav-number"></span>
          <span class="underline nav-text">ABOUT</span>
        </a>
        <ng-template ngbNavContent>About content</ng-template>
      </li>
      <li ngbNavItem>
        <a ngbNavLink (click)="redirectTo('/safety')">
          <span class="nav-number"></span>
          <span class="underline nav-text">SAFETY</span>
        </a>
        <ng-template ngbNavContent>Safety content</ng-template>
      </li>

      <!-- <li ngbNavItem >
                <a ngbNavLink (click)='scroll("about")'><span class='nav-number'> </span> <span class="underline nav-text"> {{"Header.Item5" | translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li> -->
      <li ngbNavItem>
        <a ngbNavLink (click)="redirectTo('/store')">
          <span class="nav-number"> </span>
          <span class="underline nav-text"> SHOP </span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>

      <li>
        <a ngbNavLink ngbNavItem (click)="togglePasswordInput()"
          ><span class="nav-number"> </span>
          <span class="underline nav-text"> ADMIN </span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
        <div *ngIf="showPasswordInput" class="adminAreaI">
          <input
            type="password"
            [(ngModel)]="password"
            placeholder="Enter password"
          />
          <button
            class="btn btn-primary"
            style="
              margin-left: 4px;
              padding: 3px 14px !important;
              background-color: #0054ae !important;
              transition: background 0.3s ease;
              box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
              border: none;
            "
            (click)="checkPassword()"
          >
            Submit
          </button>
        </div>
      </li>

      <li ngbNavItem *ngIf="adminSuccess">
        <a ngbNavLink (click)="redirectTo('/billing')"
          ><span class="nav-number"> </span>
          <span class="underline nav-text"> BILLING </span></a
        >
        <ng-template ngbNavContent>Second content</ng-template>
      </li>
      <li ngbNavItem>
        <a (click)="downloadPDF()" class="btn btn-outline-primary btn-list">
          Price List
        </a>
        <ng-template ngbNavContent>price content</ng-template>
      </li>

      <!-- <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
                <a ngbNavLink (click)='scroll("proyects")'><span class='nav-number'> </span> <span class="underline nav-text"> {{"Header.Item3" | translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li> -->
      <!-- <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_contact", "menu", "click")'>
                <a ngbNavLink (click)='scroll("contact")'><span class='nav-number'></span> <span class="underline nav-text"> {{"Header.Item4" | translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li> -->
      <!-- <li ngbNavItem>
                <a (click)="downloadCV()" class="btn btn-outline-primary cv-btn" ngbNavLink>
                  {{"Header.cvBtn" | translate}}
                </a>
            </li> -->
    </ul>
    <div class="query blinking-text" *ngIf="!adminSuccess">
      <div>For Queries <br> & Bulk Order </div>
      <div class="icon"><i class="fas fa-phone-alt" ></i>
      </div>
      <div>+91 9629 586 991
        <br>
        +91 8667 084 120
      </div>
    </div>
    <div class="menu-wrapper">
      <div
        [class.animate]="responsiveMenuVisible"
        (click)="toggleMenu()"
        class="hamburger-menu"
      ></div>
    </div>
    <div
      *ngIf="responsiveMenuVisible"
      class="menu-responsive"
      [ngStyle]="{ 'pointer-events': responsiveMenuVisible ? 'auto' : 'none' }"
    >
      <aside
        [class.aside-show]="responsiveMenuVisible"
        [class.nav-shadow]="this.pageYPosition > 0"
        class="on-top"
      >
        <nav>
          <ol>
            <li (click)="redirectTo('/home')">
              <a (click)="scroll('home')">
                <span>Home</span>
              </a>
            </li>

            <li (click)="redirectTo('/about')">
              <a (click)="scroll('about')">
                <span>About</span>
              </a>
            </li>

            <li (click)="redirectTo('/safety')">
              <a>
                <span>Safety</span>
              </a>
            </li>
            <li (click)="redirectTo('/store')">
              <a (click)="scroll('image')">
                <span>Shop</span>
              </a>
            </li>

            <li>
              <a
                (click)="downloadPDF()"
                class="btn btn-outline-primary btn-list mobileprice"
              >
                Price List
              </a>
            </li>

            <!-- <li
              (click)="
                analyticsService.sendAnalyticEvent(
                  'click_jobs',
                  'menu',
                  'click'
                )
              "
            >
              <a (click)="scroll('proyects')">
                <span>03. </span> {{ "Header.Item3" | translate }}
              </a>
            </li>
            <li
              (click)="
                analyticsService.sendAnalyticEvent(
                  'click_contact',
                  'menu',
                  'click'
                )
              "
            >
              <a (click)="scroll('proyects')">
                <span>04. </span> {{ "Header.Item4" | translate }}
              </a>
            </li>
            <li>
              <a (click)="downloadCV()" class="btn btn-outline-primary cv-btn">
                {{ "Header.cvBtn" | translate }}
              </a>
            </li> -->

            <!-- <li>
                            <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                        </li> -->
          </ol>
        </nav>
      </aside>
    </div>
  
</nav>
