<div class="safetytipsbg">
    <div class="container pad">
        <div class="row"></div>
    </div>
</div>



<div class="container pad">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12 pb-4">
            <h2 class="boogaloo heading1 pb-3 clr-red">Viswai Crackers</h2>
            <p >There are certain Dos &amp; Don'ts to follow while purchasing, bursting, and storing crackers. It is crucial to adhere to the precautions while bursting crackers, as a little negligence, ignorance, or carelessness can lead to a fatal injury.</p>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
            <h2 class="block-head boogaloo font">Do's</h2>
            <ul>
                <li class="pt-4 d-flex">
                    <p class="tick"><i class="fas fa-check"></i></p> 
                    <div class="text4">
                        <h4 class="boogaloo fw-800 heading5">Instructions</h4>
                        <p >Display fireworks as per the instructions mentioned on the pack.</p>
                    </div>
                </li>
                <li class="pt-3 d-flex">
                    <p class="tick"><i class="fas fa-check"></i></p> 
                    <div class="text4">
                        <h4 class="boogaloo heading5">Outdoor</h4>
                        <p >Use fireworks only outdoors.</p>
                    </div>
                </li>
                <li class="pt-3 d-flex">
                    <p class="tick"><i class="fas fa-check"></i></p> 
                    <div class="text4">
                        <h4 class="boogaloo heading5">Branded Fireworks</h4>
                        <p >Buy fireworks from authorized/reputed manufacturers only.</p>
                    </div>
                </li>
                <li class="pt-3 d-flex">
                    <p class="tick"><i class="fas fa-check"></i></p> 
                    <div class="text4">
                        <h4 class="boogaloo heading5">Distance</h4>
                        <p >Light only one firework at a time, by one person. Others should watch from a safe distance.</p>
                    </div>
                </li>
                <li class="pt-3 d-flex">
                    <p class="tick"><i class="fas fa-check"></i></p> 
                    <div class="text4">
                        <h4 class="boogaloo heading5">Supervision</h4>
                        <p >Always have adult supervision.</p>
                    </div>
                </li>
                <li class="pt-3 d-flex">
                    <p class="tick"><i class="fas fa-check"></i></p> 
                    <div class="text4">
                        <h4 class="boogaloo heading5">Water</h4>
                        <p >Keep two buckets of water handy in case of fire or any mishap.</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
            <h2 class="block-head boogaloo font">Don'ts</h2>
            <ul>
                <li class="pt-4 d-flex">
                    <p class="wrong"><i class="fa fa-times"></i></p>
                    <div class="text4">
                        <h4 class="boogaloo fw-600 heading5">Don't make tricks</h4>
                        <p >Never make your own fireworks.</p>
                    </div>
                </li>
                <li class="pt-3 d-flex">
                   <p class="wrong"><i class="fa fa-times"></i></p>
                    <div class="text4">
                        <h4 class="boogaloo heading5">Don't relight</h4>
                        <p >Never try to relight or pick up fireworks that haven't fully ignited.</p>
                    </div>
                </li>
                <li class="pt-3 d-flex">
                   <p class="wrong"><i class="fa fa-times"></i></p>
                    <div class="text4">
                        <h4 class="boogaloo heading5">Don't carry it</h4>
                        <p >Never carry fireworks in your pockets.</p>
                    </div>
                </li>
                <li class="pt-3 d-flex">
                   <p class="wrong"><i class="fa fa-times"></i></p>
                    <div class="text4">
                        <h4 class="boogaloo heading5">Don't touch it</h4>
                        <p >After a fireworks display, never pick up fireworks that may be left over; they still may be active.</p>
                    </div>
                </li>
                <li class="pt-3 d-flex">
                   <p class="wrong"><i class="fa fa-times"></i></p>
                    <div class="text4">
                        <h4 class="boogaloo heading5">Do not use glass/metal</h4>
                        <p >Never shoot fireworks in metal or glass containers.</p>
                    </div>
                </li>
                <li class="pt-3 d-flex">
                   <p class="wrong"><i class="fa fa-times"></i></p>
                    <div class="text4">
                        <h4 class="boogaloo heading5">Don't wear loose clothes</h4>
                        <p >Do not wear loose clothing while using fireworks.</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>