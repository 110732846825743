import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { StoreComponent } from './components/home/store/store.component';
import { AdminComponent } from './components/admin/admin.component';
import { BillingComponent } from './components/billing/billing.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { PreviewComponent } from './components/admin/preview/preview.component';
import { AboutComponent } from './components/home/about/about.component';
import { SafetyComponent } from './components/safety/safety.component';
import { PricelistComponent } from './components/pricelist/pricelist.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'safety', component: SafetyComponent },
  { path: 'pricelist', component: PricelistComponent },
  { path: 'store', component: StoreComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'billing', component: BillingComponent },
  { path: 'dummy', component: ArchiveComponent },
  
  { path: 'preview', component: PreviewComponent },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
