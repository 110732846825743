import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TemplateManagerService {
  constructor(private firestore: AngularFirestore) {}



  getDataCollection(): Observable<any[]> {
    return this.firestore.collection('userdata').valueChanges();
  }

  getBillingDataCollection(): Observable<any[]> {
    return this.firestore.collection('billing').valueChanges();
  }

  getStoreDataCollection(): Observable<any[]> {
    return this.firestore.collection('store').valueChanges();
  }

  saveStoreData(saveJson: any): Promise<void> {
    return this.firestore.collection('store').add(saveJson)
      .then(() => {
        console.log('Document successfully written!');
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
  }


  saveBillingData(saveJson: any): Promise<void> {
    return this.firestore.collection('billing').add(saveJson)
      .then(() => {
        console.log('Document successfully written!');
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
      });
  }
  

  getPrimaryTemplateFromFB(): Observable<any> {
    
    return this.firestore.collection('templates', ref => ref.where('isPrimary', '==', true))
      .snapshotChanges()
      .pipe(
        map(actions => {
          if (actions.length > 0) {
            
            const data = actions[0].payload.doc.data() as any;
            const id = actions[0].payload.doc.id;
            return { id, ...data };
          }
          return null; // Return null if no primary template is found
        })
      );
  }

  getTemplateById(templateId: string): Observable<any> {
    return this.firestore.collection('templates').doc(templateId)
      .snapshotChanges()
      .pipe(
        map(action => {
          const data = action.payload.data() as any;
          const id = action.payload.id;
          return { id, ...data };
        })
      );
  }
}
