import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, importProvidersFrom } from '@angular/core';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './components/home/home.module';
import { GeneralModule } from './components/general/general.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import "./firework-prototype";
import { AdminComponent } from './components/admin/admin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import firebase from 'firebase/compat/app'
import { ImageComponent } from './image/image.component';
import { FullScreenImageComponent } from './components/full-screen-image/full-screen-image.component';
import { NgbCollapseModule, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BillingComponent } from './components/billing/billing.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { KartComponent } from './components/kart/kart.component';
import { TabComponent } from './components/admin/tab/tab.component';
import { TabsComponent } from './components/admin/tabs/tabs.component';
import { ScrollComponent } from './components/scroll/scroll.component';
import { PreviewComponent } from './components/admin/preview/preview.component';
import { SafetyComponent } from './components/safety/safety.component';
import { PricelistComponent } from './components/pricelist/pricelist.component';
import { RouterLink } from '@angular/router';
export const initializeLoginFramework = !firebase.apps.length ? firebase.initializeApp(environment.firebase) : firebase.app()


export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


export function appInitializer() {
  return () => firebase.initializeApp(environment.firebase);
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    ImageComponent,
    FullScreenImageComponent,
    BillingComponent,
    KartComponent,
    TabComponent,
    TabsComponent,
    ScrollComponent,
    PreviewComponent,
    SafetyComponent,
    PricelistComponent
    /* ArchiveComponent */
  ],
  imports: [
    BrowserAnimationsModule,
    GeneralModule,
    AnimateOnScrollModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HomeModule,
    RouterLink,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    NgbModalModule,
    NgbModule,
    CarouselModule,
    NgbCollapseModule,
    
  ],
  providers: [TranslateService, 
    importProvidersFrom(provideFirebaseApp(() => initializeApp(environment.firebase))),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
