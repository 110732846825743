<section class="section contact" id='contact'>
    <div class="container">
        <div class="section-box">
            <div class="mb-4" data-aos="fade-up" data-aos-duration="1000">
                <h3 class="contact-pre-title big-subtitle">What’s Next</h3>
                <h4 class="e-font contact-title big-title">
                    Get in touch!
                </h4>
            </div>
            <p data-aos="fade-up" data-aos-duration="1000">
                We would greatly value your feedback as a customer. Please share your thoughts, and I will respond promptly to your message. We have created a simple one-page design that includes a brief profile letter with essential information about myself. Your input on this would be much appreciated",
            </p>
            <textarea id="feedback" name="feedback" rows="4" cols="40" spellcheck="false" placeholder="Write Something..." style=" background-color: #aca7a70f;
            border: 1px solid #9E9E9E;
            border-radius: 6px;
            height: 80px;
            font-size: 14px;
            width: 350px;
            padding: 10px;
            color: #000;
            font-weight: 300;" ></textarea>
            <div class="mt-5">
                <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "contact", "email")' href="mailto:viswaicrackers@gmail.com" target="_blank" class="btn btn-outline-primary" data-aos="fade-up" data-aos-duration="1000" >
                    Send
                </a>
            </div>
        </div>
    </div>
</section>