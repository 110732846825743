import { Component, OnInit, AfterViewInit } from '@angular/core';
import { trigger, query, stagger, style, animate, transition } from "@angular/animations"

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
})

export class ArchiveComponent {

 
  constructor() { }


}
