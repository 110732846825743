import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateManagerService } from 'src/app/services/fire/template.service';

@Component({
  selector: 'app-full-screen-image',
  templateUrl: './full-screen-image.component.html',
  styleUrls: ['./full-screen-image.component.scss']
})
export class FullScreenImageComponent {
  @Input() imageUrl: string;
  @Input() imageName: string;

  constructor(public modal: NgbActiveModal,
    private templateManagerService: TemplateManagerService
  ) {
   
  }
}
